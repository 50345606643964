<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <el-row>
                                        <el-col :span="12" align="left">
                                            <a class="text-white">
                                            <div class="btn btn-light font-weight-bold text-uppercase download-btn">Download Invoice</div>
                                        </a>
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <a class="text-white">
                                            <div class="btn btn-primary font-weight-bold text-uppercase print-section" @click="printWindow()">Print Invoice</div>
                                        </a>
                                        </el-col>
                                 </el-row>
                                  <el-row>
                                        <el-col :span="12" align="left">
                                            <img src="https://ctshub.s3.ca-central-1.amazonaws.com/images/cts-logo.png" />
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">733 Progress Ave, Scarborough, ON M1H 2W7, Canada.</label><br>
                                        <label>Phone: +1 416-840-4538</label><br>
                                        <label>Fax: +1 416-840-4538</label><br>
                                        <label>Email: Info@ctsbuildingsupplies.com</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>
                                      <el-col :span="6" align="left">
                                        <img src="@/assets/images/barcode.jpg" style=" width: 200px; height: 45px;"/>
                                      </el-col>
                                      <el-col :span="12" align="left">
                                      <h2 style="font-family: Arial;text-align: center;"><b>ORDER CONFIRMATION</b></h2>
                                      </el-col>
                                      <el-col :span="6" align="left">
                                      </el-col>                                      
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER #</b></span><span><b> {{view.order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID :</b></span><span><b> WAR456</b></span>
                                       </el-col>
                                  </el-row>
                                        <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO :</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.address_line_1 || view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}{{view.billing_address_order.address_line_1 ? ', Unit No : '+view.billing_address_order.address_line_1 : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name+' - '+view.billing_address_order.post_code}}</div>
                                      </el-col>
                                       <el-col :span="8" align="">
                                            .
                                      </el-col>
                                       <el-col :span="8" align="left">                                          
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.address_line_1 || view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}{{view.shipping_address_order.address_line_1 ? ', Unit No : '+view.shipping_address_order.address_line_1 : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name+' - '+view.shipping_address_order.post_code}}</div>
                                      </el-col>
                                    </el-row>
                                      <div class="row">
                                      <div class="col-xl-12 ">
                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >DATE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >CUSTOMER P.O#</div></th>
                                                    <th aria-colindex="2" role="cell"><div >ORDER PLACED BY</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SALESPERSON</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SHIP VIA</div></th>
                                                    <th aria-colindex="2" role="cell"><div >TERMS</div></th>
                                                </tr>
                                                <tr class="billing-cl3">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{format_date(view.created_at)}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">564564646</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">Adam</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">SUBA</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{view.ship_via}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">COD</div></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center" style="background-color:#003366; color: #ffffff">
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >DESCRIPTION</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Qty</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Type</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Price</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Amount</div></th>
                                                </tr>
                                                <tr class="billing-cl3" v-for="item in view.order_items" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.product_item[0].sku}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.product_item[0].description}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{item.quantity}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{view.type}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">$ {{item.product_item[0].sale_price}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10"><span style="float:left">$</span> {{item.price}}</div></td>
                                                </tr>
                                                <tr class="billing-cl3">
                                                  <td aria-colindex="2" role="cell" colspan="4" >
                                                      <div class="padding-10"><b><u>Other Comments or Special Instructions</u></b></div>
                                                      <div class="padding-10"></div>
                                                      <div class="padding-10" style="margin-top:40px;"><b>Total Weight: </b></div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" >
                                                      <div class="padding-10">Sub Total</div>
                                                      <div class="padding-10">HST</div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10">Total</div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" class="text-center">
                                                      <div class="padding-10"><span style="float:left">$</span>{{view.sub_total}}</div>
                                                      <div class="padding-10">
                                                          <span style="float:left">$</span>
                                                          <span v-if="view.order_items[0].product_item[0].app_tax>0">{{view.order_items[0].product_item[0].app_tax}}</span>
                                                          <span v-else>--</span>
                                                       </div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10"><span style="float:left">$</span>{{view.total}}</div>
                                                      </td>  
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                      </div>
                                       <el-row>
                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>                                     
                              
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getCorder} from "@/api/order";
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Management", route: "/order/list" },
      { title: "View Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getCorder(this.$route.params.order_id).then(response => {
         // console.log(response);
        this.view = response.data.data[0]      
        this.loading = false
      });
    },
    printWindow: function () {		
	 window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    },    
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    @media print {
       
        .print-section, .download-btn, .subheader {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    

}
 
</style>